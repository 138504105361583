export const GetSimiliarArticles = async ({ storyId }: any) => {
	const apiUrl: string = process.env.NEXT_PUBLIC_IS_DEV ?? '';
	return await fetch(
		`${
			Number(apiUrl) == 1
				? '/api?action=articles'
				: 'https://smi2.ru/newdata/jsapi?action=articles'
		}`,
		{
			method: 'POST',
			cache: 'force-cache',
			next: { revalidate: 1 },
			mode: 'cors',
			body: JSON.stringify({
				block_id: 84683,
				fields: 4194303,
				image_width: 250,
				image_height: 150,
				count: 100,
				ctx: 'ARTICLES',
				sort_type: 'DATE',
				story_article: Number(storyId),
				filter_ids: [Number(storyId)]
			}),
			headers: new Headers({ 'content-type': 'application/json' })
		}
	);
};
