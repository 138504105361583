import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetComments } from '../Api/Fetchs/getPostComment';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}
export function* getPostsCommentSaga(
	action: ReturnType<typeof getComments>
): any {
	try {
		yield put(getPostsCommentLoading());
		const params = action.payload.params || {};
		const response = yield call(GetComments, params);
		const payload = yield call([response, 'json']);
		yield put(getPostsCommentSuccess(payload));
		console.log(`Request: fetch('/api?action=article_details', {
		mode: 'cors',
		method: 'POST',
		cache: 'force-cache',
		next: { revalidate: 60 },
		body: JSON.stringify({
			block_id: 84683,
			article_id: ${params},
			fields: 4194303,
			image_height: 150,
			image_width: 250
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});`);
		console.log(`Response:`, payload);
	} catch (error) {
		yield put(getPostsCommentError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostComment = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsCommentLoading: state => {
			state.Loading = true;
		},
		getPostsCommentSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsCommentError: (state, action) => {
			state.Error = action.payload;
		},
		restDataComments: state => {
			state.data = [];
		}
	}
});

export const GET_COMMENTS = 'article/getComments';

export const getComments = createAction<{ params?: any }>(GET_COMMENTS);

export const {
	getPostsCommentSuccess,
	getPostsCommentLoading,
	getPostsCommentError,
	restDataComments
} = getPostComment.actions;
export default getPostComment.reducer;
