export const GetProperty = async () => {
	const apiUrl: string = process.env.NEXT_PUBLIC_IS_DEV ?? '';
	return await fetch(
		`${
			Number(apiUrl) == 1
				? '/api?action=properties'
				: 'https://smi2.ru/newdata/jsapi?action=properties'
		}`,
		{
			method: 'POST',
			mode: 'cors',
			cache: 'force-cache',
			next: { revalidate: 300 },
			body: JSON.stringify({
				block_id: 84683,
				tags_type: 'MAIN'
			}),
			headers: new Headers({ 'content-type': 'application/json' })
			// next: { revalidate: 18000 }
		}
	);
};
