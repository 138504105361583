import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"cyrillic\",\"latin\",\"cyrillic-ext\",\"latin-ext\"],\"display\":\"swap\",\"style\":\"normal\",\"preload\":true}],\"variableName\":\"PtSans\"}");
import(/* webpackMode: "eager" */ "/app/src/app/globals.sass");
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
import(/* webpackMode: "eager" */ "/app/src/components/Header/header.module.sass");
import(/* webpackMode: "eager" */ "/app/src/Features/BtnScrollUp/ScrollUp.tsx");
import(/* webpackMode: "eager" */ "/app/src/Features/TopHeaderLinks/TopHederLinks.module.sass");
import(/* webpackMode: "eager" */ "/app/src/components/Sidebar/sidebar.module.sass");
import(/* webpackMode: "eager" */ "/app/src/lib/getProperty.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/Ym.tsx");
import(/* webpackMode: "eager" */ "/app/src/Redux/Store/ProviderClient.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/InfoLine/date.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/LicenceBlock/licence.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/MobileBurger/burger.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/MobileNav/nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/QuizSlider/quiz.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/QuoteSlider/quote.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/topHeader/GeoTopHeader/GeoTopHeader.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/topHeader/TopHeader.module.sass");
import(/* webpackMode: "eager" */ "/app/src/Widgets/UnderHeader/UnderHeader.tsx");
import(/* webpackMode: "eager" */ "/app/src/Widgets/UploadBlock/upload.module.sass");
import(/* webpackMode: "eager" */ "/app/src/Widgets/VideoSlider/video.tsx")